import { createContext, useReducer } from "react";
import { v4 } from "uuid";
import  appReducer from "./AppReducer"




let initialState


if(localStorage.getItem('Articulos')?.length > 0) {
  initialState = {
    Articulos: JSON.parse(localStorage?.getItem("Articulos"))
  }

}else{
  initialState = {
    Articulos: [
    ],
  };
}


export const GlobalContext = createContext(initialState);

export const ContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(appReducer, initialState)

    const addArticle = (Articulo) => dispatch({type:'ADD_ARTICLE', payload:{...Articulo, Articulo, id: v4()}})
    
    const updateArticle = (Articulo) => dispatch({type:'UPDATE_ARTICLE', payload: Articulo })
    const deleteArticle = (id) =>  dispatch({type:'DELETE_ARTICLE', payload:id})

    const deleteAllArticle = (Articulos) => dispatch({type:'DELETE_ALL_ARTICLE', payload:Articulos})

  return (
    <GlobalContext.Provider value={{...state, addArticle, deleteArticle, updateArticle, deleteAllArticle}}>
      {children}
    </GlobalContext.Provider>
  );
};
