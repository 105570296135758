export default function appReducer(state, action) {

const localArticulos = JSON.parse(localStorage?.getItem("Articulos"))

switch (action.type) {
    case 'ADD_ARTICLE':
        
        return{
            Articulos: [...state?.Articulos, action?.payload]
             
            }
    case 'DELETE_ARTICLE':
        const pos = Object.keys(localArticulos).indexOf('id')
        localArticulos.splice(pos, 1)
        
        localStorage.setItem("Articulos", JSON.stringify(localArticulos))
        return {
            Articulos: state?.Articulos?.filter(Articulo => Articulo?.id !== action?.payload)           
        }

        case 'DELETE_ALL_ARTICLE':
        localStorage?.setItem("Articulos", JSON.stringify([]))
        return {
            Articulos: state?.Articulos           
        }

    case 'UPDATE_ARTICLE': 
        const updatedArticle = action.payload

     const updateTasks =  state?.Articulos?.map(Articulo => {
            if(Articulo?.id === updatedArticle?.id){
                Articulo.id = updatedArticle?.id
                Articulo.ItemName = updatedArticle?.ItemName
                Articulo.BarCode = updatedArticle?.BarCode
                Articulo.Mainsupplier = updatedArticle?.Mainsupplier
                Articulo.SupplierCatalogNo = updatedArticle?.SupplierCatalogNo
                Articulo.SWW = updatedArticle?.SupplierCatalogNo
                Articulo.ArTaxCode = updatedArticle?.ArTaxCode
                Articulo.ApTaxCode = updatedArticle?.ArTaxCode
                Articulo.U_Rubro = updatedArticle?.U_Rubro
                Articulo.U_Subrubros = updatedArticle?.U_Subrubros
                Articulo.ItemsGroupCode = updatedArticle?.ItemsGroupCode
                Articulo.U_Marca = updatedArticle?.U_Marca
                Articulo.Price = updatedArticle?.Price
                Articulo.ItemPrices[0].Price = updatedArticle?.ItemPrices[1]?.factor
            
            }
            return Articulo

        })
         return {
            Articulos: updateTasks
         }

    default:
        break;
}
}