import { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import { GlobalContext } from "../context/GlobalContext.js";
import axios from "axios";



export function useFormatTransferItems() {
  const [cookies] = useCookies();

  const [formatedTransferItems, setFormatedTransferItems] = useState({});
  const [materialRevaluationLines, setMaterialRevaluationLines] = useState({});
  const [lastModelName,setlastModelName] = useState("")

  console.log("materialRevaluation:", materialRevaluationLines);

  const { Articulos } = useContext(GlobalContext);
  
  console.log(Articulos)

  
  
  useEffect(() => {
    let Division = "";

    if (cookies.Division === "DH") {
      Division = "Hogar";
    }
    if (cookies.Division === "DM") {
      Division = "Motos";
    }
    if (cookies.Division === "DB") {
      Division = "Bikes";
    }
    if (cookies.Division === "DR") {
      Division = "Repuestos";
    }
    if (cookies.Division === "DN") {
      Division = "Neumaticos";
    }
   

    if ( cookies.Division === "DN") {
      const lines = Articulos.map((obj) => {
        console.log("soy obj", obj);

        return {
          ...obj,
          ItemName: obj?.ItemName?.toUpperCase(),
          Mainsupplier: obj?.Mainsupplier?.value,
          Series: parseInt(obj?.Series),
          ItemsGroupCode: obj?.ItemsGroupCode?.value,
          SWW: obj?.SupplierCatalogNo,
          U_Marca: obj?.U_Marca?.value,
          U_Rubro: obj?.U_Rubro?.value,
          U_Subrubros: obj?.U_Subrubros?.value,
          U_Division: cookies?.Division,
        };
      });

      lines.forEach((obj) => {
        delete obj.Articulo && delete obj.Price && delete obj.U_ano;
      });
      const linesPrice = Articulos.map((obj) => ({
        Comments: "Revalorizacion Automatica",
        DataSource: "I",
        MaterialRevaluationLines: [
          {
            ItemCode: "",
            Price: !!obj.ItemPrices[1].Price ? obj.ItemPrices[1].Price.toString().replace(".", ",") : "",
            WarehouseCode: "001",
            Project: Division,
          },
        ],
        RevalType: "P",
      }));
      setMaterialRevaluationLines(linesPrice);
      setFormatedTransferItems(lines);
      console.log(linesPrice);
    }if (cookies.Division === "DR") {
      const lines = Articulos.map((obj) => {
        console.log("soy obj", obj);

        return {
          ...obj,
          ItemName: obj?.ItemName?.toUpperCase(),
          Mainsupplier: obj?.Mainsupplier?.value,
          Series: parseInt(obj?.Series),
          ItemsGroupCode: obj?.ItemsGroupCode?.value,
          SWW: obj?.SupplierCatalogNo,
          U_Marca: obj?.U_Marca?.value,
          U_Rubro: obj?.U_Rubro?.value,
          U_Subrubros: obj?.U_Subrubros?.value,
          U_Division: cookies?.Division,
        };
      });

      lines.forEach((obj) => {
        delete obj.Articulo && delete obj.Price && delete obj.U_ano;
      });
      const linesPrice = Articulos.map((obj) => ({
        Comments: "Revalorizacion Automatica",
        DataSource: "I",
        MaterialRevaluationLines: [
          {
            ItemCode: "",
            Price: !!obj.ItemPrices[1].Price ? obj.ItemPrices[1].Price.toString().replace(".", ",") : "",
            WarehouseCode: "001",
            Project: Division,
          },
        ],
        RevalType: "P",
      }));
      setMaterialRevaluationLines(linesPrice);
      setFormatedTransferItems(lines);
      console.log("soy linesPrice",linesPrice);
    }
     else if (cookies.Division === "DB") {
      const lines = Articulos.map((obj) => ({
        ...obj,
        ItemName: obj?.ItemName?.toUpperCase(),
        CostAccountingMethod: "bis_Standard",
        Mainsupplier: obj?.Mainsupplier?.value,
        Series: parseInt(obj?.Series),
        ManageSerialNumbers: !!obj.ManageSerialNumbers
          ? obj.ManageSerialNumbers
          : "tNO",
        ItemsGroupCode: obj?.ItemsGroupCode?.value,
        SWW: "",
        U_Marca: obj?.U_Marca?.value,
        U_Rubro: obj?.U_Rubro?.value,
        U_Subrubros: obj?.U_Subrubros?.value,
        U_Division: cookies?.Division,
      }));

      lines.forEach((obj) => {
        delete obj.Articulo && delete obj.Price;
      });

      const linesPrice = Articulos.map((obj) => ({
        Comments: "Revalorizacion Automatica",
        DataSource: "I",
        MaterialRevaluationLines: [
          {
            ItemCode: "",
            Price: !!obj.ItemPrices[1].Price ? obj.ItemPrices[1].Price.toString().replace(".", ",") : "",
            WarehouseCode: "001",
            Project: Division,
          },
        ],
        RevalType: "P",
      }));

      setMaterialRevaluationLines(linesPrice);
      console.log(linesPrice);
      setFormatedTransferItems(lines);

    } else if (cookies.Division === "DM") {
      const queryOptions = {
        orderby: "Code desc",
        top: 1,
      };
      // Fetch the last model's code from the API
      axios
        .post("http://45.227.163.223:3002/api/v1/all/MODELOS", queryOptions)
        .then((response) => {
          // Calculate the new model code
          setlastModelName(response.data.details[0].Code);
          console.log("soy lastModel", lastModelName);
        });
        
      const lines = Articulos.map((obj) => ({
        ...obj,
        
        ItemName: obj?.ItemName?.toUpperCase(),
        CostAccountingMethod:
          obj?.U_Rubro.value === "000016" ? "bis_SNB" : "bis_Standard",
        Mainsupplier: obj?.Mainsupplier?.value,
        Series: obj.U_Rubro.value === "000016" ? 3 : 156,
        ManageSerialNumbers:
          obj.ManageSerialNumbers && obj?.U_Rubro?.value === "000016"
            ? "tYES"
            : "tNO",
        ItemsGroupCode: obj?.ItemsGroupCode?.value,
        SWW: obj?.SupplierCatalogNo,
        U_Marca: obj?.U_Marca?.value,
        U_Rubro: obj?.U_Rubro?.value,
        U_Subrubros: obj?.U_Subrubros?.value,
        U_Division: cookies?.Division,
        U_Modelo: lastModelName,
        
      
      })
      ); 
      //console.log("soy lines",lines)
    
      // Verifica si es motos y el rubro es "000016"
      if (lines.some((obj) => obj.U_Rubro === "000016")) {
        lines.forEach((obj) => {
   // Guarda el valor de ItemPrices en U_importe antes de eliminarlo

   obj.U_importe = obj.ItemPrices ? obj.ItemPrices[0].Factor : null;
   obj.U_costo = obj.ItemPrices ? obj.ItemPrices[1].Price : null;
   obj.ItemCode = obj.U_Marca + obj.U_Modelo;
   

          // Elimina los campos deseados
          delete obj.Articulo;
          delete obj.ItemPrices;
          
        });
    
        // No realiza la revalorización
        const linesPrice = [];
    
        setMaterialRevaluationLines(linesPrice);
        setFormatedTransferItems(lines);
      } else {
        // En caso contrario, realiza la lógica normal
        lines.forEach((obj) => {
          delete obj.Articulo && delete obj.Price && delete obj.U_ano && delete obj.U_importe && delete obj.U_costo;
        });
    
        const linesPrice = Articulos.map((obj) => ({
          Comments: "Revalorizacion Automatica",
          DataSource: "I",
          MaterialRevaluationLines: [
            {
              ItemCode: "M" + obj.U_Modelo,
              Price: !!obj.ItemPrices[1].Price ? obj.ItemPrices[1].Price.toString().replace(".", ",") : "",
              WarehouseCode: "001",
              Project: Division,
            },
          ],
          RevalType: "P",
        }));
    
        setMaterialRevaluationLines(linesPrice);
        setFormatedTransferItems(lines);
      }
    } else if (cookies.Division === "DH") {
      const lines = Articulos.map((obj) => ({
        ...obj,
        ItemName: obj?.ItemName?.toUpperCase(),
        CostAccountingMethod: "bis_Standard",
        Mainsupplier: obj?.Mainsupplier?.value,
        Series: parseInt(obj?.Series),
        ManageSerialNumbers:
          obj.ManageSerialNumbers && obj?.U_Subrubros?.value === "00000067"
            ? "tYES"
            : "tNO",
        ItemsGroupCode: obj?.ItemsGroupCode?.value,
        SWW: obj?.SupplierCatalogNo,
        U_Marca: obj?.U_Marca?.value,
        U_Rubro: obj?.U_Rubro?.value,
        U_Subrubros: obj?.U_Subrubros?.value,
        U_Division: cookies?.Division,
      }));

      lines.forEach((obj) => {
        delete obj.Articulo &&
          delete obj.Price &&
          delete obj.U_Modelo &&
          delete obj.U_ano;
      });

      const linesPrice = Articulos.map((obj) => ({
        Comments: "Revalorizacion Automatica",
        DataSource: "I",
        MaterialRevaluationLines: [
          {
            ItemCode: "",
            Price: !!obj.ItemPrices[1].Price ? obj.ItemPrices[1].Price.toString().replace(".", ",") : "",
            WarehouseCode: "001",
            Project: Division,
          },
        ],
        RevalType: "P",
      }));

      setMaterialRevaluationLines(linesPrice);
      //console.log(linesPrice)
      setFormatedTransferItems(lines);
    }
  }, [Articulos, cookies.Division,lastModelName]);

  return { formatedTransferItems, materialRevaluationLines };
}
